<template>
  <div>
      <h1>ddd</h1>
  </div>
</template>

<script>
export default {
    name:"ContentManager"
}
</script>

<style>

</style>